export default {
  colors: {
    background: '#ffffff',
    text: '#121212',
    primary: '#003e7e',
    secondary: '#e9e9e9',
    tertiary: '#f3f3f3',
    highlight: '#5b8bf7',
    primaryColor: '#003E7E',
    secondaryColor: '#FDC122',
    tertiaryColor: '#007C8F',
    toastSuccess: '#64C173',
    toastInfo: '#2F86EB',
    toastWarning: '#FFC021',
    toastError: '#DC3546',
    textMain: '#555555',
    textSecondary: '#949494',
    textFootNote: '#CDCDCD',
    inverted: '#fff',
    btnPlate: '#E4E6EB'
  },
  fonts: {
    body:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px'
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em'
  }
}
